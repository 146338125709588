@use 'base/_reset.scss';

@use 'base/_layout.scss';
@use 'base/_type.scss';

@use 'components/_logo.scss';
@use 'components/_input.scss';
@use 'components/_button.scss';
@use 'components/_rule.scss';
@use 'components/_header.scss';
@use 'components/_footer.scss';
@use 'components/_newsletter.scss';

@use 'sections/_pages.scss';
@use 'sections/_hero.scss';
@use 'sections/_trailer.scss';
@use 'sections/_features.scss';
@use 'sections/_community.scss';

@use '~aos/dist/aos.css';